<template>
  <layout>

    <template #secondarySidebar>
      <reports-nav />
    </template>

    <reports-toolbar
      title="Hourly Report"
      v-model:filters="state.filters"
      :machines="state.machines"
      @update-filters="updateFilters"
    />

    <!-- main content wrapper, check other grpahs for use -->
    <div class="flex flex-col space-y-4  mt-6 xl:mt-8">

    </div>

  </layout>
</template>

<script>
import { reactive } from 'vue'
// import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
// import http from '@/services/http.js'
// import loading from '@/helpers/loading.js'
import date from '@/helpers/date.js'
// import money from '@/helpers/money.js'

import Layout from '@/layouts/Default.vue'
import ReportsNav from '@/components/reports/Nav.vue'
import ReportsToolbar from '@/components/reports/Toolbar.vue'
// import { IconLoading } from '@/components/icons'

export default {
  name: 'ReportsHourly',

  components: {
    Layout,
    ReportsNav,
    ReportsToolbar,
    // IconLoading,
  },

  setup() {
    // const router = useRouter()
    // const route = useRoute()
    const state = reactive({
      apiError: false,
      perDayChartToggle: false,
      perDayChartZoom: 3,
      per_day_vends: [],
      per_day_revenues: [],
      averages: {},
      table: [],
      machines: [],
      totals: {},
      isOpen: false,
      filters: {
        type: 'all',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        machines: [],
        range: {
          from: date.now().subtract(1, 'day').subtract(1, 'week').format('YYYY-MM-DD'),
          to: date.now().format('YYYY-MM-DD'),
        }
      },
    })
    // const machineIdsString = computed(() => state.filters.machines.join(','))
    // const percentages = computed(() => ({
    //   iceRevenue: parseInt(state.totals.ice_revenue / state.totals.total_revenue * 100),
    //   waterRevenue: parseInt(state.totals.water_revenue / state.totals.total_revenue * 100),
    //   iceVends: parseInt(state.totals.ice_vends / state.totals.total_vends * 100),
    //   waterVends: parseInt(state.totals.water_vends / state.totals.total_vends * 100),
    //   avgVendsCount: state.totals.total_vends_previous === 0 ? 0 : parseInt((state.totals.total_vends - state.totals.total_vends_previous) / state.totals.total_vends_previous * 100.0),
    //   avgChangeDow: state.totals.total_vends_previous_dow === 0 ? 0 : parseInt((state.totals.total_vends - state.totals.total_vends_previous_dow) / state.totals.total_vends_previous_dow * 100.0),
    // }))

    // const hydrateFiltersFromUrl = () => {
    //   state.filters.machines = route.query.machines ? route.query.machines.split(',').map((m) => parseInt(m)) : []
    //   route.query.from ? state.filters.range.from = route.query.from : null
    //   route.query.to ? state.filters.range.to = route.query.to : null
    //   route.query.timezone ? state.filters.timezone = route.query.timezone : null
    //   route.query.type ? state.filters.type = route.query.type : null
    //   state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))
    // }

    // const updateFilters = () => {
    //   router.replace({
    //     query: {
    //       from: state.filters.range.from,
    //       to: state.filters.range.to,
    //       timezone: state.filters.timezone,
    //       machines: machineIdsString.value,
    //       type: state.filters.type,
    //     }
    //   })
    // }

    // const getMachines = async () => {
    //   hydrateFiltersFromUrl()

    //   const { data: machines } = await http.get('user/machines')
    //   state.machines = state.selectedMachines = machines.allowed_locations

    //   if (state.filters.machines.length === 0) {
    //     state.filters.machines = machines.allowed_locations.map((machine) => machine.location_id)
    //     updateFilters()
    //   } else {
    //     getData()
    //   }
    //   state.selectedMachines = state.machines.filter((machine) => state.filters.machines.includes(machine.location_id))
    // }

    // const pollRangeData = async (guid, isRecursive = false) => {
    //   if (isRecursive) {
    //     await new Promise((resolve) => setTimeout(() => resolve(), 1000))
    //   }

    //   try {
    //     const { data } = await http.get(`/task/result/${guid}`)

    //     if (data.error) {
    //       state.apiError = data.error
    //     }
    //     if (data.data) {
    //       return data.data
    //     }

    //     return await pollRangeData(guid, true)
    //   } catch (e) {
    //     if (e.response) {
    //       e.response.data.Message ? state.apiError = e.response.data.Message : state.apiError = 'Server Error'
    //     } else {
    //       state.apiError = 'Request failed'
    //     }
    //   }
    // }

    // const getData = async () => {
    //   loading.start()

    //   try {
    //     const { data } = await http.post('task/request', {
    //       task_type: 'report_range',
    //       data: {
    //         location_ids: state.filters.machines,
    //         start: state.filters.range.from,
    //         end: state.filters.range.to,
    //         timezone: state.filters.timezone,
    //         type: state.filters.type
    //       }
    //     })

    //     const [rangeData, { data: weather }] = await Promise.all([
    //       pollRangeData(data.guid),
    //       http.get('report/weather', {
    //         params: {
    //           location_ids: machineIdsString.value,
    //           start: state.filters.range.from,
    //           end: state.filters.range.to,
    //           timezone: state.filters.timezone,
    //           type: state.filters.type
    //         }
    //       }),
    //     ])

    //     state.apiError = false
    //     state.per_day_vends = rangeData.per_day_vends
    //     state.per_day_revenue = rangeData.per_day_revenue
    //     state.averages.vends = rangeData.averages
    //     state.table = rangeData.table
    //     state.totals = rangeData.totals
    //     state.averages.per_day_weather = weather.per_day_weather
    //     weather.per_location_weather.forEach((weather) => {
    //       const table = state.table.find((t) => t.location_id === weather.location_id)
    //       table.temp = weather.temp
    //     })
    //   } catch (e) {
    //     if (e.response) {
    //       e.response.data.Message ? state.apiError = e.response.data.Message : state.apiError = 'Server Error'
    //     } else {
    //       state.apiError = 'Request failed'
    //     }
    //   }

    //   loading.end()
    // }

    // onMounted(() => {
    //   state.filters.isAdvancedFiltersVisible = window.innerWidth > 768
    // })

    // watch(
    //   () => route.query,
    //   () => {
    //     // Set timeout to get correct query params from URL
    //     setTimeout(() => {
    //       hydrateFiltersFromUrl()
    //       getData()
    //     }, 100)
    //   }
    // )

    // onBeforeRouteUpdate(() => {
    //   setTimeout(() => {
    //     if (Object.keys(route.query).length === 0 && route.query.constructor === Object) {
    //       updateFilters()
    //     }
    //   }, 100)
    // })

    // loading.start()
    // getMachines()

    return {
      state,
      // isLoading: loading.status,
      // money,
      // getData,
      // updateFilters,
      // percentages,
      // reload: () => location.reload(),
    }
  }
}
</script>
